
//http://localhost:8080/#/claim/EnquiryProgram
import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    nextTick,
    onMounted,
    computed,
} from 'vue';
import { getQueryList, deleteInfo } from '@/API/claim/claimQuery';
import {
    getModelYear,
    getBuList,
    getRegionLst,
    getDealerList,
    getProgramList,
    getCheckingRoundList,
    getTypeClass,
    getCesarAnalyst,
    getCesarBased,
    getDealerGroup,
    getRegion,
} from '@/API/salesReporting/claimCheckingReport';
import { message, TreeSelect } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
// import setColumnWidth from "@/utils/claim/setColumnWidth"
import downloadFile from '@/utils/claim/downloadFile';
import moment, { Moment } from 'moment';
import { useStore } from 'vuex';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { useRoute } from 'vue-router';
import { getDealerByGroupId } from '@/API/salesReporting';
import { findBrandByBu, getDealerByBUID } from '@/API/salesReporting';

const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const tableWidth = window.innerWidth;
const avgCol = reactive([
    {
        title: 'Dealer Code(Cofico)',
        dataIndex: 'dealerConficoCode',
        width: 200,
    },
    { title: 'Dealer Code (SWT)', dataIndex: 'dealerSwtCode', width: 200 },
    { title: 'Dealer Name(CN)', dataIndex: 'dealerNameCn', width: 200 },
    { title: 'Dealer Name(EN)', dataIndex: 'dealerNameEn', width: 200 },
    {
        title: 'Dealer Group(CN)',
        dataIndex: 'dealerGroupCn',
        width: 200,
    },
    {
        title: 'Dealer Group(EN)',
        dataIndex: 'dealerGroupEn',
        width: 200,
    },
    // {
    //     title: 'Dealer Group(CN&EN)',
    //     dataIndex: 'dealerGroupNameCn',
    //     width: 200,
    //     slots: { customRender: 'dealerGroup' },
    // },
    { title: 'Sub Region', dataIndex: 'subRegion', width: 200 },
    { title: 'Business Unit', dataIndex: 'bu', width: 200 },
    {
        title: 'CESAR Submission VIN No.',
        dataIndex: 'cesarSubmitNo',
        width: 200,
    },
    {
        title: 'OASIS Submission VIN No.',
        dataIndex: 'oasisSubmitNo',
        width: 200,
    },
    { title: 'Gap Submission VIN No.', dataIndex: 'gapSubmitNo', width: 200 },
    { title: 'Duration/Day', dataIndex: 'durationDay', width: 200 },
    { title: 'Avg Gap/VIN ', dataIndex: 'avgGapVin', width: 200 },
    { title: 'Avg Gap/Day', dataIndex: 'avgGapDay', width: 200 },
]);

const weekBasedFixedColumns = [
    {
        title: 'Dealer Code(Cofico)',
        dataIndex: 'dealerConficoCode',
        width: 200,
    },
    { title: 'Dealer Code(SWT)', dataIndex: 'dealerSwtCode', width: 200 },
    { title: 'Dealer Name(CN)', dataIndex: 'dealerNameCn', width: 250 },
    { title: 'Dealer Name(EN)', dataIndex: 'dealerNameEn', width: 200 },
    {
        title: 'Dealer Group(CN)',
        dataIndex: 'dealerGroupCn',
        width: 200,
    },
    {
        title: 'Dealer Group(EN)',
        dataIndex: 'dealerGroupEn',
        width: 200,
    },
    // {
    //     title: 'Dealer Group(CN&EN)',
    //     dataIndex: 'dealerGroup',
    //     width: 200,
    // },
    { title: 'Sub Region', dataIndex: 'subRegion', width: 200 },
    { title: 'Business Unit', dataIndex: 'bu', width: 200 },
];

interface TreeDataItem {
    value: string;
    key: string;
    title?: string;
    disabled?: boolean;
    children?: TreeDataItem[];
}
const qmOptions: TreeDataItem[] = [
    {
        title: 'Q1',
        value: 'Q1',
        key: 'Q1',
        children: [
            {
                title: '01',
                value: '01',
                key: '01',
            },
            {
                title: '02',
                value: '02',
                key: '02',
            },
            {
                title: '03',
                value: '03',
                key: '03',
            },
        ],
    },
    {
        title: 'Q2',
        value: 'Q2',
        key: 'Q2',
        children: [
            {
                title: '04',
                value: '04',
                key: '04',
            },
            {
                title: '05',
                value: '05',
                key: '05',
            },
            {
                title: '06',
                value: '06',
                key: '06',
            },
        ],
    },
    {
        title: 'Q3',
        value: 'Q3',
        key: 'Q3',
        children: [
            {
                title: '07',
                value: '07',
                key: '07',
            },
            {
                title: '08',
                value: '08',
                key: '08',
            },
            {
                title: '09',
                value: '09',
                key: '09',
            },
        ],
    },
    {
        title: 'Q4',
        value: 'Q4',
        key: 'Q4',
        children: [
            {
                title: '10',
                value: '10',
                key: '10',
            },
            {
                title: '11',
                value: '11',
                key: '11',
            },
            {
                title: '12',
                value: '12',
                key: '12',
            },
        ],
    },
];
type Key = ColumnProps['key'];
export default defineComponent({
    components: {},
    setup() {
        const activeKey = ref('1');
        const spaceSize = 6;
        const { state } = useStore();
        const searchCon = ref();
        const tableHeight = ref();
        const tableHeight2 = ref();

        //显示的表格数据
        let buCopy = '';
        const dealerListData = ref<any[]>([]);
        const programListData = ref<any[]>([]);
        const parameterList = ref<any>({
            bu: null,
            region: null,
            brand: 'All',
            cofico: '',
            swt: '',
            cn: '',
            dealerGroup: '',
        });
        const retailPeriod = ref<Moment[]>([moment(), moment()]);
        const retailMonth = ref<Moment>(moment());
        // //period初始化
        // const claimPeriodYear = ref<string>(moment().format('YYYY'));
        // const openClaimPeriodYear = ref<boolean>(false);
        // const claimPeriodDis = ref<boolean>(false);
        // const claimPeriodQM = ref<string[]>([`Q${moment().quarter()}`]);
        // const handlerOpenClaimPeriodChange = (status: any): void => {
        //     if (status) {
        //         openClaimPeriodYear.value = true;
        //     }
        // };
        // const handlerPanelClaimPeriodChange = (value: string): void => {
        //     const time = moment(value).format('YYYY');
        //     claimPeriodYear.value = time as string;
        //     openClaimPeriodYear.value = false;
        //     if (time) {
        //         claimPeriodDis.value = false;
        //     } else {
        //         claimPeriodDis.value = true;
        //         claimPeriodQM.value = [];
        //     }
        // };
        // const claimPeriodChange = (value: string) => {
        //     if (value) {
        //         claimPeriodDis.value = false;
        //     } else {
        //         claimPeriodDis.value = true;
        //         claimPeriodQM.value = [];
        //     }
        // };

        //获取bu选项,region选项
        const buList = computed(() => {
            return state.user.buList
                .filter((bu: any) => {
                    return bu.parentId === null;
                })
                .filter((bu: any) => {
                    return bu.nameEn !== 'Companycar' && bu.nameEn !== 'CPO';
                });
        });
        const brandList = ref<any[]>([]);
        const regionList = ref<any[]>([]);

        const getSelectBuId = () => {
            const index = buList.value.findIndex(
                (item: any) => item.nameEn === parameterList.value.bu
            );
            return buList.value[index]?.id;
        };
        const dogetRegion = () => {
            parameterList.value.region = null;
            getRegionLst({ bu: parameterList.value.bu }).then((res) => {
                const sortArr: any[] = [[], [], [], [], []];
                const maps = {
                    n: 0,
                    e: 1,
                    s: 3,
                    w: 2,
                };
                res.forEach((region: any) => {
                    const firstLetter = (region
                        .subRegionEn[0] as string).toLocaleLowerCase();
                    if (firstLetter in maps) {
                        sortArr[maps[firstLetter]].push(region);
                    } else {
                        sortArr[4].push(region);
                    }
                });
                regionList.value = sortArr.flat();
            });
        };

        const getBrandList = () => {
            parameterList.value.brand = 'All';
            const buId = getSelectBuId();
            findBrandByBu({ buIds: [buId] }).then((res) => {
                if (res.length === 0) {
                    brandList.value = [];
                } else {
                    const bdList = res[0].brandList.filter((bu: any) => {
                        return bu.brandNameEn !== 'DENZA';
                    });
                    bdList.push({ brandNameEn: 'All' });
                    brandList.value = bdList;
                }
            });
        };

        const dogetDefault = () => {
            if (buList.value.length === 0) return;
            const mbIndex = buList.value.findIndex((i: any) => {
                return i.nameEn === 'MBPC';
            });
            const initIndex = mbIndex === -1 ? 0 : mbIndex;
            const value = buList.value[initIndex].nameEn;
            parameterList.value.bu = value;
            buCopy = value;
        };
        dogetDefault();
        // 获取dealergroup
        const dealerGroupList = ref([]);
        const genDealerGroup = () => {
            getDealerGroup().then((res: any) => {
                dealerGroupList.value = res;
            });
        };
        genDealerGroup();

        const handleBuChange = () => {
            // searchParams.dealerCodeCofico = null;
            // searchParams.dealerCodeSWT = null;
            // searchParams.dealerName = null;
            // searchParams.dealerGroup = null;
            dogetRegion();
            getBrandList();
            // getDealerInfos();
        };

        //重置
        const handlerReset = () => {
            parameterList.value.bu = buCopy;
            parameterList.value.region = null;
            parameterList.value.brand = 'All';
            parameterList.value.cofico = '';
            parameterList.value.swt = '';
            parameterList.value.cn = '';
            parameterList.value.dealerGroup = '';
            retailPeriod.value = [moment(), moment()];
            retailMonth.value = moment();
        };

        const dogetParameter = () => {
            const parameterDataLst = {
                bu: '',
                buId: '',
                brand: '',
                region: '',
                startDate: '',
                endDate: '',
                retailMonth: '',
                cofico: '',
                swt: '',
                cn: '',
                group: '',
                downFlag: '',
            };
            parameterDataLst.brand = parameterList.value.brand;
            parameterDataLst.cofico = parameterList.value.cofico;
            parameterDataLst.swt = parameterList.value.swt;
            parameterDataLst.cn = parameterList.value.cn;
            parameterDataLst.group = parameterList.value.dealerGroup;
            if (parameterList.value.bu) {
                parameterDataLst.bu = parameterList.value.bu;
                if (parameterList.value.region) {
                    parameterDataLst.region = parameterList.value.region;
                }
            }

            parameterDataLst.buId = getSelectBuId();

            if (activeKey.value === '1') {
                parameterDataLst.startDate = retailPeriod.value[0]?.format(
                    'YYYY/MM/DD'
                );
                parameterDataLst.endDate = retailPeriod.value[1]?.format(
                    'YYYY/MM/DD'
                );
            }

            if (activeKey.value === '2') {
                parameterDataLst.retailMonth = retailMonth.value.format(
                    'YYYY/MM'
                );
            }

            return parameterDataLst;
        };

        const calculateHeight = () => {
            (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height =
                window.innerHeight - 180 - searchCon.value.scrollHeight + 'px';
            const height = (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height;
            const heightNum = parseInt(height.substr(0, height.indexOf('px')));
            tableHeight.value = heightNum - 150;
            tableHeight2.value = heightNum - 150;
        };
        nextTick(() => {
            calculateHeight();
        });

        const getAvgAnalysisData = () => {
            const params = dogetParameter();
            getCesarAnalyst(params).then((res) => {
                dealerListData.value = res;
                console.log('dealerListData', dealerListData.value);
            });
        };

        const weekBasedColumns = ref<any[]>([...weekBasedFixedColumns]);
        const weekBasedWidth = computed(() => {
            const width = weekBasedColumns.value.reduce(
                (total: number, col: any) => {
                    if ('width' in col) {
                        return total + col['width'];
                    } else {
                        return total + 400;
                    }
                },
                0
            );
            return width;
        });
        const getWeekBasedData = () => {
            const params = dogetParameter();
            getCesarBased(params).then((res) => {
                if (res.length === 0) {
                    programListData.value = [];
                    return;
                }
                const dynamicColumns = res[0].items.map((item: any) => {
                    return {
                        title: item.title,
                        children: [
                            {
                                title: 'CESAR Submission VIN No.',
                                dataIndex: `${item.title}_cesarNo`,
                                width: 200,
                            },
                            {
                                title: 'OASIS Submission VIN No.',
                                dataIndex: `${item.title}_oasisNo`,
                                width: 200,
                            },
                        ],
                    };
                });
                // console.log('dynamicColumns', dynamicColumns);
                weekBasedColumns.value = [
                    ...weekBasedFixedColumns,
                    ...dynamicColumns,
                ];
                const dataList = res.map((row: any) => {
                    const childrenCols = row.items.reduce(
                        (obj: any, item: any) => {
                            return {
                                ...obj,
                                [`${item.title}_cesarNo`]: item['cesarNo'],
                                [`${item.title}_oasisNo`]: item['oasisNo'],
                            };
                        },
                        {}
                    );
                    return { ...row, ...childrenCols };
                });
                // console.log('dataList', dataList);
                programListData.value = dataList;
            });
        };
        const getDataByActiveKey = () => {
            // if (!claimPeriodYear.value) {
            //     message.error('Retail period (Year) and BU must be selected!');
            //     return;
            // }
            if (!retailPeriod.value[0] && !retailPeriod.value[1]) {
              retailPeriod.value[0] = moment().startOf('month')
              retailPeriod.value[1] = moment()
            }
            if (activeKey.value === '1') {
                getAvgAnalysisData();
            } else {
                getWeekBasedData();
            }
        };

        //切换tab
        const tabChange = () => {
            getDataByActiveKey();
        };

        const downloadTableData = [
            {
                file: 'Raw Data',
                fileType: 'rawdata',
            },
            {
                file: 'Report Data',
                fileType: 'form',
            },
        ];
        const downloadRowSelection = reactive<{
            selectedRowKeys: Key[];
            selectedRowData: any[];
            onChange: Function;
        }>({
            onChange: (selectedRowKeys: Key[], selectedRows: any[]) => {
                downloadRowSelection.selectedRowKeys = selectedRowKeys;
                downloadRowSelection.selectedRowData = selectedRows;
            },
            selectedRowData: [],
            selectedRowKeys: [],
        });
        const downloadColumnsData = [
            { title: 'File', dataIndex: 'file', align: 'left' },
        ];
        const downloadVisible = ref(false);
        const openDownload = () => {
            downloadVisible.value = true;
        };
        const closeDownload = () => {
            downloadVisible.value = false;
            downloadRowSelection.selectedRowKeys.length = 0;
        };

        const handlerDownload = () => {
            if (downloadRowSelection.selectedRowKeys.length < 1) {
                message.warning('请先选择数据');
                return;
            }
            const params = dogetParameter();
            if (downloadRowSelection.selectedRowKeys.length === 2) {
                params.downFlag = `zip`;
            } else {
                params.downFlag = downloadRowSelection
                    .selectedRowKeys[0] as string;
            }

            const url =
                activeKey.value === '1'
                    ? '/rvapi/report/cesar/download/analy'
                    : '/rvapi/report/cesar/download/weekBase';

            const paramsConfig = {
                url,
                method: 'get',
                params: params,
            };
            downloadFile(paramsConfig, 'application/vnd-excel; char-set=UTF-8');
            closeDownload();
        };

        const disabledDate = (current: Moment) => {
            return current && current > moment().endOf('day');
        };

        onMounted(() => {
            handleBuChange();
            getDataByActiveKey();
            window.addEventListener('resize', calculateHeight);
        });

        return {
            spaceSize,
            downloadTableData,
            downloadRowSelection,
            downloadColumnsData,
            downloadVisible,
            openDownload,
            closeDownload,
            tabChange,
            genDealerGroup,
            dealerGroupList,
            handlerDownload,
            getDataByActiveKey,
            dogetParameter,
            buList,
            handleBuChange,
            brandList,
            handlerReset,
            regionList,
            retailPeriod,
            retailMonth,
            // claimPeriodChange,
            // claimPeriodDis,
            // claimPeriodQM,
            // qmOptions,
            // handlerPanelClaimPeriodChange,
            // handlerOpenClaimPeriodChange,
            // openClaimPeriodYear,
            // claimPeriodYear,
            parameterList,
            getWeekBasedData,
            getAvgAnalysisData,
            searchCon,
            tableWidth,
            tableHeight,
            tableHeight2,
            avgCol,
            dealerListData,
            programListData,
            weekBasedColumns,
            SHOW_PARENT,
            activeKey,
            weekBasedWidth,
            disabledDate,
        };
    },
});
